<template>
  <div
    v-if="processName"
    :class="$style.titleProcesses"
  >
    <template v-if="titleEditable">
      <title-editable
        :title="processName"
        :disabled="loading"
        placeholder="Process name"
        @cancel="titleValidationError = null"
        @input="changeProcessName"
      />

      <Spinner
        v-if="loading"
        :class="$style.spinner"
      />

      <div
        :class="$style.validations"
      >
        {{ titleValidationError }}
      </div>
    </template>

    <Title
      v-else
      :title="processName"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import Title from '@/components/title.vue';
import TitleEditable from '@/components/title-editable.vue';
import Spinner from '@/components/spinner.vue';

export default {
  name: 'TitleProcesses',
  components: {
    Title,
    TitleEditable,
    Spinner,
  },
  data() {
    return {
      loading: false,
      titleValidationError: null,
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processName', 'processId']),

    titleEditable() {
      return this.$route.meta?.titleEditable;
    },
  },
  methods: {
    ...mapActions(useProcessesStore, ['updateProcessName']),

    async changeProcessName(value) {
      this.titleValidationError = null;

      if (!value) return (this.titleValidationError = 'Please, fill the process name');
      if (value === this.processName) return value;

      this.loading = true;

      const { error } = await this.updateProcessName({ id: this.processId, name: value });

      this.loading = false;

      if (error) {
        return (this.titleValidationError = 'Please, provide the unique process name');
      }

      return value;
    },
  },
};
</script>

<style lang="scss" module>
.titleProcesses {
  position: relative;
  display: flex;
  align-items: center;

  .spinner {
    flex: 0 0 auto;
    margin-left: 10px;
  }

  .validations {
    position: absolute;
    left: 0;
    bottom: -23px;
    padding-top: 5px;
    font-size: 12px;
    white-space: nowrap;
    color: var(--color-danger);
  }
}
</style>
