<template>
  <div
    :class="$style.title"
  >
    <Title
      v-if="!isEditing"
      :title="title"
      @dblclick.native="edit"
    />

    <div
      v-if="isEditing"
      ref="changedTitle"
      :contenteditable="!disabled"
      :class="[
        $style.changedTitle,
        disabled && $style['changedTitle--disabled'],
        emptyChangedTitle && $style['changedTitle--empty']
      ]"
      :data-placeholder="emptyChangedTitle && placeholder"
      @keydown.prevent.enter="save"
      @keydown.esc="cancel"
      @input="changeTitle"
    >
      {{ changedTitle }}
    </div>

    <div
      v-show="editable && !disabled"
      :class="$style.controls"
    >
      <template v-if="!isEditing">
        <div
          :class="$style.edit"
          title="Change title"
          @click="edit"
        >
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </template>

      <template v-if="isEditing">
        <div
          :class="$style.save"
          title="Save"
          @click="save"
        >
          <font-awesome-icon :icon="['fal', 'check']" />
        </div>

        <div
          :class="$style.cancel"
          title="Cancel"
          @click="cancel"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Title from '@/components/title.vue';

export default {
  name: 'TitleWithControls',
  components: {
    Title,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      changedTitle: '',
    };
  },
  computed: {
    emptyChangedTitle() {
      return this.changedTitle.length === 0;
    },
  },
  watch: {
    title: {
      handler() {
        this.cancel();
      },
      immediate: true,
    },
    isEditing(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.changedTitle.focus();

          // Set cursor position at the end of contenteditable element
          const sel = document.getSelection();

          sel.collapse(sel.anchorNode, this.changedTitle.length + 1);
        });
      }
    },
    disabled(value) {
      if (!value) {
        this.$nextTick(() => {
          this.$refs.changedTitle?.focus();
        });
      }
    },
  },
  methods: {
    changeTitle({ target: { innerText } }) {
      // Set cursor position at the entered character
      // By default content editable set cursor to the start after component rerender
      const sel = document.getSelection();
      const offset = sel.anchorOffset;
      const anchorNode = sel.anchorNode;
      this.changedTitle = innerText;

      this.$nextTick(() => {
        if (offset === 1 && anchorNode.data !== anchorNode.data.trim()) sel.collapse(sel.anchorNode, offset + 1);
        else sel.collapse(sel.anchorNode, offset);
      });
    },

    edit() {
      return this.editable && (this.isEditing = true);
    },
    cancel() {
      this.$emit('cancel');

      this.isEditing = false;
      this.changedTitle = this.title;
    },
    save() {
      this.$emit('input', this.changedTitle);

      if (this.title === this.changedTitle) this.cancel();
    },
  },
};
</script>

<style lang="scss" module>
.title {
  display: flex;
  align-items: stretch;

  .changedTitle {
    position: relative;
    outline: none;
    font-size: 24px;
    line-height: 32px;
    word-break: break-all;
    color: var(--color-main);

    &::before {
      content: attr(data-placeholder);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      font-size: 16px;
      opacity: 0.5;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 1px;
      background-color: var(--color-main);
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;

      &::after {
        display: none;
      }
    }

    &--empty {
      min-width: 100px;
    }
  }

  .controls {
    position: relative;
    display: flex;
    align-items: stretch;
    gap: 10px;
    margin-left: 10px;
    color: var(--color-main);

    .edit,
    .save,
    .cancel {
      display: flex;
      align-items: center;
      transition-duration: var(--transition-duration);
      cursor: pointer;

      &:hover {
        transform: scale(1.4);
      }
    }

    svg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
